import { makeStyles } from "@mui/styles";

const style = makeStyles({
  tagLabel: {
    marginBottom: "10px !important",
  },
  selectAddressRadioContainer: {
    display: "flex !important",
    marginLeft: "10px !important",
  },
  formControlLabel: {
    flex: "1 !important",
  },
  addAddressContainer: {
    display: "flex !important",
  },
  tagRadio: {
    marginRight: "5px !important",
    marginBottom: "5px !important",
  },
});

export default style;
