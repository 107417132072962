const MuiTabs = {
  styleOverrides: {
    root: {
      textTransform: "capitalize !important",
      borderBottom: "1px solid #E2E8F0",
    },
  },
};

export default MuiTabs;
