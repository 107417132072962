const MuiTab = {
  styleOverrides: {
    root: {
      textTransform: "capitalize !important",
      fontSize: "14px !important",
      fontWeight: "600 !important",
      lineHeight: "20px !important",
    },
  },
};

export default MuiTab;
