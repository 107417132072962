import { makeStyles } from "@mui/styles";

const style = makeStyles({
  ordersContainer: {
    padding: "45px 52px",
  },
  orderHistoryTypo: {
    fontSize: "32px !important",
  },
  tabTanelContainer: {
    marginTop: "16px !important",
  },
});

export default style;
