import { makeStyles } from "@mui/styles";

const style = makeStyles({
  loader: {
    textAlign: "center",
    marginTop: "24px",
  },
});

export default style;
